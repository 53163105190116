import * as Queries from "./queries";
import { GraphQLParameters, CurrentUserContainer, CreateBoardReportContainer, ReplContainer, ReplReportParameters } from "./entities";

const url = "https://replit-graphql-proxy.akac.workers.dev";

async function request(parameters: GraphQLParameters | GraphQLParameters[], sid?: string, init?: RequestInit) {
  if (!init) init = {};

  init.body = JSON.stringify(parameters);

  if (sid) {
    init.headers = {
      Authorization: sid
    };
  }

  init.headers = {
    ...init.headers,
    "Content-Type": "application-json"
  };

  init.method = "POST";

  let res = await fetch(`${url}/graphql`, init);

  return res;
}

async function parse<T>(res: Response) {
  let output: T = JSON.parse(await res.text()) as T;

  return output;
}

export default class ReplitClient {
  sid: string;

  constructor(sid: string) {
    this.sid = sid;
  }

  async currentUser() {
    let res = await request({ query: Queries.getCurrentUser }, this.sid);
    let obj = await parse<CurrentUserContainer>(res);

    return obj?.data?.currentUser;
  }

  async reportRepl(id: string, reason: string) {
    let res = await request(
      {
        query: Queries.reportRepl,
        variables: {
          replId: id,
          reason: reason
        }
      },
      this.sid
    );

    let obj = await parse<CreateBoardReportContainer>(res);
    return obj?.data?.createBoardReport?.id;
  }

  async replByUrl(url: string) {
    let res = await request(
      {
        query: Queries.replByUrl,
        variables: {
          url
        }
      },
      this.sid
    );

    let obj = await parse<ReplContainer>(res);
    return obj?.data?.repl;
  }

  async reportRepls(parameters: ReplReportParameters[]) {
    let res = await request(
      parameters.map((x) => {
        return {
          query: Queries.reportRepl,
          variables: {
            replId: x.id,
            reason: x.reason
          }
        };
      }),
      this.sid
    );

    let obj = await parse<CreateBoardReportContainer[]>(res);
    return obj?.map((x) => x?.data?.createBoardReport?.id).filter((x) => x);
  }

  async replsByUrls(urls: string[]) {
    let res = await request(
      urls.map((x) => {
        return {
          query: Queries.replByUrl,
          variables: {
            url: x
          }
        };
      }),
      this.sid
    );

    let obj = await parse<ReplContainer[]>(res);
    return obj?.map((x) => x.data?.repl).filter((x) => x);
  }
}
