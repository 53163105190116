export const getCurrentUser = "query { currentUser { id username firstName lastName bio isVerified displayName fullName url isLoggedIn timeCreated isHacker image email state { id skillLevel interestedIn } notificationCount } }";

/*
//User
export const userByUsername = "query userByUsername($username: String!) { user: userByUsername(username: $username) { } }";
export const userById = "query user($id: Int!) { user(id: $id) { } }";
export const warnUser = "mutation WarnUser($username: String!, $reason: String!) { warnUser(username: $username, reason: $reason) { ... on Warning { id } ... on UserError { message } } }";
export const reportUser = "mutation ReportUser($reportedUserId: Int, $reason: String!) { createBoardReport(reportedUserId: $reportedUserId, reason: $reason) { id reportedUser { id } }}";

//Repl
export const replByUrl = "query ReplByURL($url: String!) { repl(url: $url) { ... on Repl { } } }";
export const replById = "query ReplById($id: String!) { repl(id: $id) { ... on Repl { } } }";
export const replsByUsername = "query userByUsername($username: String!, $count: Int!) { user: userByUsername(username: $username) { id username exportRepls(count: $count) { items { } } } }";
export const reportRepl = "mutation ReportRepl($replId: String, $reason: String!) { createBoardReport(replId: $replId, reason: $reason) { id } }";
export const forksByUrl = "query ForksByURL($url: String!, $after: String!, $count: Int!) { repl(url: $url) { ... on Repl { exportForks(count: $count, after: $after) { items { } } } } }";
export const forksById = "query ForksByID($id: String!, $after: String!, $count: Int!) { repl(id: $id) { ... on Repl { exportForks(count: $count, after: $after) { items { } } } } }";

//Misc
export const sendNotification = "mutation moderatorNotification($username: String!, $url: String!, $text: String!) { moderatorNotification( input: { username: $username url: $url text: $text } ) { __typename } }";

//Community
export const replPostsFeed = "query ReplPostsFeed($options: ReplPostsQueryOptions) { replPosts(options: $options) { pageInfo { nextCursor } items { id title timeCreated repl { } } } }";
export const templates = "query UseTemplates2($options: TemplateRepls2QueryOptions!) { templateRepls2(options: $options) { ... on TemplateReplSearchConnection { total pageInfo { nextCursor } items { INSERT releases(count: 1) { items { id pending timeCreated timeUpdated } } } } } }";

//Anti-Abuse
export const searchSpamComments = "query searchSpamComments($dateMin: DateTime!, $dateMax: DateTime!, $pattern: String!, $isRegex: Boolean!, $count: Int!, $hidden: Boolean!) { searchSpamComments(dateMin: $dateMin, dateMax: $dateMax, pattern: $pattern, isRegex: $isRegex, count: $count, hidden: $hidden) { } }";
export const deleteComment = "mutation ReplViewCommentsDeleteReplComment($id: Int!) { deleteReplComment(id: $id) { ... on ReplComment { id } ... on UserError { message } } }";
*/

//Repl
export const reportRepl = "mutation ReportRepl($replId: String, $reason: String!) { createBoardReport(replId: $replId, reason: $reason) { id } }";
export const replByUrl = "query ReplByURL($url: String!) { repl(url: $url) { ... on Repl { id slug } } }";
