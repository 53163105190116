import ReplitClient from "../modules/replit";

declare global {
  interface Window {
    ReplitClient: unknown;
  }
}

async function main() {
  window.ReplitClient = ReplitClient;
}

main();

export {};
